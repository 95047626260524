.main_container {
  &_inner {
    width: 100%;
  }

  :global {
    .nav {
      // padding-inline: 1.25rem; //padding chnages to margin
      padding-top: 20px;
      margin-bottom: 10px;
      flex-wrap: nowrap;
      background-color: var(--pro-light);
      z-index: 9;

      // border-bottom: 0; //removed the border of nav
      .nav-link {
        white-space: pre;

        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;

          &:not(.active) {
            border: 1px solid transparent;
            border-bottom: 0;
          }

          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            &::after {
              background-color: var(--pro-primary);
            }
          }

          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}



.report_root {
  max-width: 170px;
  padding-right: 17px;
  margin-right: 17px;
  border-right: 1px solid var(--pro-outline-border);

  &:first-child~.item {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0px;
    max-width: 500px;
  }
}