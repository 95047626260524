.root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.influencer_conatiner {
  display: flex;
  background-color: var(--pro-secondary);
  margin: 5px 0;
  justify-content: space-between;
}
