.main_container {
  &_inner {
    width: 100%;
  }
  :global {
    .nav {
      // padding-inline: 1.25rem; //padding chnages to margin
      padding-top: 20px;
      margin-bottom: 10px;
      flex-wrap: nowrap;
      background-color: var(--pro-light);
      z-index: 9;
      // border-bottom: 0; //removed the border of nav
      .nav-link {
        white-space: pre;
        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;
          &:not(.active){
            border: 1px solid transparent;
            border-bottom: 0;
          }
          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            &::after {
              background-color: var(--pro-primary);
            }
          }
          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}

.offcanvas {
  --pro-offcanvas-width: 500px !important;
}
.brand {
  display: flex;
  align-items: center;
  :global(.pro-avatar){
    --wac-avatar-size: 30px;
  }
  img{
    object-fit: contain;
  } 
}


.campaign_title{
  max-width: 174px;
}
.content_verify_wrap{
  position: relative;
  :global{
    .pro-badge{
      position: absolute;
      top:20px;
      right: 0;
      background-color: #fff;
    }
  }
}
.img_verify_wrap{
  position: relative;
  padding: 20px 0;
  border-bottom: 1px dashed #ccc;
  figure{
    max-height: 200px;
    margin-bottom:25px;
    img{
      width: 100%;
      height: 200px;
      object-fit: contain;
      display: block;
      margin: auto;
    }
  }
}
.video_verify_wrap{
  position: relative;
  padding: 20px 0;
}
.approve_reject_wrap{
  p{
    font-size: 14px;
    margin-bottom: 10px;
    strong{
      font-weight: 500;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.name{
  max-width: 200px;
}