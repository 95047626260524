
.header {
  background-color: var(--pro-light);
  box-shadow: 0 0 4px 1px rgb(9 30 66 / 13%), 0 0 4px 0 rgb(9 30 66 / 8%);
  padding: 10px 0;
  width: 100vw;
  right: initial;
  padding-right: 0 !important;
  :global(.nav-link){
    --pro-nav-link-padding-y:0;
  }
  :global(.nav-link .pro-btn-link::before){
    bottom: -14px;
  }
  :global(.dropdown) button:global(.dropdown-toggle){
    border-radius: 50%;
    outline-color: var(--pro-border-color-translucent);
  }
  a >figure{
    min-width: 129px;
    max-width: 129px;
    img{
      max-width: 100%;
    }
  }
  .header_inner{
    padding-left: 1.5rem;
    >div:first-of-type{
      padding-right: 3.75rem;
    }
  }
}
.MenuDrawer_container {
  :global{
    .nav-link .material-symbols-outlined {
      max-width: 22px;
    }
    .secondary .nav.nav-pills .nav-link{
      --pro-nav-link-hover-color: var(--pro-dark);
      --pro-nav-pills-link-active-color: var(--pro-dark);
      &:not(.active):hover{
        background-color: var(--pro-nav-pills-link-active-bg);
      }
    }
    .secondary{
      background-color: var(--pro-light);
      box-shadow: none;
      border-right: 1px solid var(--pro-outline-border);
    }
  }
}
:global(.sub-icon) {
  width: 24px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  opacity: 1;
  transition: 0.3s all ease;
}
:global(.expanded .sub-icon) {
  opacity: 0;
}


[data-theme="dark"] .header{
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.1);
}