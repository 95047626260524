
.influencer_conatiner{
    background-color: var(--pro-secondary);
    & + .influencer_conatiner{
        margin-top: 12px;
    }
}
.input_wrap{
    width: auto;
    input{
        width: auto;
        min-width: 75px;
        max-width: 90px;
        padding-right: 2.5rem;
    }
    .error{
        position: absolute;
        top: calc(100% + 2px);
        white-space: pre;
    }
    .currency{
        position: absolute;
        top: 0;
        right: 0.9rem;
        z-index: 2;
        top: 20px;
        transform: translateY(-50%);
    }
}

.platform_box{
    min-width: 144px;
    background-color: var(--pro-light);
    border: 1px solid var(--pro-outline-border);
    height: 100%;
    min-height: 40px;
    max-width: 250px;
}