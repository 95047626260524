.root {
  position: relative;
}
.box_wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  // height: 60px;
  input {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
    &.error + .input_box {
      border: 1px dashed var(--pro-danger);
    }
    &:hover + .input_box {
      background-color: var(--pro-input-hover-bg);
    }
  }
  .input_box {
    height: 60px;
    border: 1px dashed var(--pro-outline-border);
    pointer-events: none;
    border-radius: 3px;
    padding: 5px;
    transition: 0.3s all ease;
    color: var(--pro-input-text);
    .text {
      > span {
        color: var(--pro-dark);
      }
    }
    > span + span {
      margin-top: 2px;
    }
  }
}
.img_wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  background-color: #fdf5f7;
  border-radius: 3px;
  border: 1px solid rgba(229, 47, 94, 0.2);
  .img {
    position: relative;
    width: 100%;
    max-width: 3rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .delete_btn {
    position: absolute;
    color: var(--pro-light);
    background-color: var(--pro-primary);
    padding: 3px;
    top: 2px;
    left: 50%;
    transform: translateX(7px);
    cursor: pointer;
    span {
      font-size: 10px !important;
    }
  }
}
