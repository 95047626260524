.main_wrapper {
  width: 100%;
  position: relative;
  padding-right: calc(var(--pro-gutter-x) * 0.5);
  padding-left: 0;
}

.avatar_container {
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}

.left_sidebar {
  width: 100%;
  max-width: 24.063rem;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;
  overflow: hidden;
  border-right: 1px solid var(--pro-outline-border);
  &_sticky {
    position: relative;
    .order_process_wrap {
      padding: 24px 0;
    }
    .order_details {
      .badge {
        --wac-badge-bg: #0f8d1b;
        --wac-badge-border-color: #0f8d1b;
        --wac-badge-color: var(--pro-light);
        text-transform: capitalize;
        border-radius: 0;
        padding: 4px 8px;
        line-height: 1.16;
      }
    }

    .order_items_wrap {
      padding: 24px 0;
      border-top: 1px solid var(--pro-outline-border);
      margin-top: 12px;
    }
    .order_item {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 20px;
      cursor: pointer;
      .order_item_image {
        position: relative;
        width: 100%;
        max-width: 37.5px;
        box-shadow: 1px 1px 1px 0px #0000007d;
      }
      .item_description {
        line-height: 14px;
        max-width: 150px;
      }
      &:not(.active) {
        &:first-of-type {
          border-top: 1px solid transparent;
        }
        & + .order_item:not(.active) {
          border-top: 1px solid var(--pro-outline-border);
        }
      }
      &.active {
        background-color: #8e0e6d0d;
        border-block: 1px solid var(--pro-primary);
        & + .order_item.active {
          border-top: 0;
        }
      }
    }
  }
  .profile_layout_action {
    ul {
      padding: 0;
      li {
        display: flex;
        justify-content: center !important;
      }
    }
  }
  .profile_name_wrap :global(p) {
    margin-top: 5px;
  }

  .side_total_wrap {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    padding: 24px;
    background-color: var(--pro-light);
    box-shadow: 0px -3px 13px 0px rgba(0, 0, 0, 0.05);
  }
}
:global {
  .pt-custom {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.main_container {
  width: 100%;
  max-width: calc(100% - 385px);
  transition: 0.3s max-width ease-in-out;
  flex: 1;
  &_inner {
    width: 100%;
  }
  :global {
    .nav {
      padding-inline: 1.25rem; //padding chnages to margin
      padding-top: 20px;
      overflow: auto;
      flex-wrap: nowrap;
      overflow-y: hidden;
      position: sticky;
      top: 60px;
      background-color: var(--pro-light);
      z-index: 9;
      // border-bottom: 0; //removed the border of nav
      .nav-link {
        white-space: pre;
        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;
          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 7px;
            border: none;
            box-shadow: 0 0 0 1px var(--pro-outline-border);
            &::after {
              background-color: var(--pro-primary);
            }
          }
          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}
.profile {
  width: 100%;
  > div {
    border-radius: 5px;
    overflow: hidden;
  }
}




.order_process {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .tick_btn{
    .check_tick{
      position: absolute;
      top: 0;
      right: 0;
      &.check_disable{
        z-index: 1;
      }
      &.checked{
        z-index: 0;
      }

    }
  }
  &.active {
    background-color: var(--pro-input-hover-bg);
    .tick_btn {
      opacity: 1;
      .check_tick{
        &.check_disable{
          z-index: 0;
        }
        &.checked{
          z-index: 1 ;
        }

      }
    }
    
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--pro-outline-border);
  }
  &:not(.active) {
    cursor: pointer;
    .tick_btn {
      opacity: 0.5;
      transition: all 0.2s;
      position: relative;
      &:hover{
        opacity: 1;
      }
      .check_tick{
        position: absolute;
        top: 0;
        right: 0;
        &.check_disable{
          z-index: 1;
        }
        &.checked{
          z-index: 0;
        }

      }
      
    }
  }
  :global{
    .pro-btn{
      &:hover,&.active {
        color: var(--pro-green);
        background-color: transparent !important;
      }
    }
  }
  
}
.amount_desc_wrap{
  position: relative;
  border-bottom: 1px dashed var(--pro-outline-border);

  .amount_wrap{
    flex: 0 0 165px;
    color: var(--pro-dark);
    .amount{
      flex: 1;
      font-size: 13px;
      font-weight: 700 ;
      color: var(--pro-dark);
      span{
        color: var(--pro-success);
      }
    }
  }
  .desc{
    flex: 1;
    font-size: 14px;
    line-height: 1.5;
  }
}
.social_amount{
  p{
    font-size: 14px;
  }
}
.total_amount{
  padding-top: 10px;
  border-top: 1px solid #ccc;
  p{
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
  }
}
.amount_input_wrap{
  margin-top: 30px;
  border: 1px solid var(--pro-outline-border);
  background-color: var(--pro-outline-active);
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: right;
  :global{
    .input-wrap{
      font-weight: 700;
      display: flex;
      justify-content: right;
      align-items: center;
      flex-direction: row;
      margin-bottom: 0 !important;
      
      
      label{
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 15px;
        margin-bottom: 0;
      }
      input{
        
        font-weight:500;
        width: 60px;
        background-color: #fff;
        &.error{
          border: 1px solid var(--pro-danger);
        }
      }
      .error-text{
        position: absolute;
        top: inherit;
        bottom: 0;
        right: 72px;

      }
    }
  }
}
