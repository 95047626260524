.box_container {
    background-color: var(--pro-secondary);
    border: 1px solid var(--pro-outline-border);
    position: relative;

    &+.box_container {
        margin-top: 5px;
    }

    .dlt_btn {
        position: absolute;
        right: 6px;
        top: 6px;
        z-index: 1;
    }
}

.delete_btn {
    width: 35px;
    height: 40px;
}

.outer_wrap {
    padding: 10px;
    border: 1px solid var(--pro-outline-border);
    border-radius: 4px;
    background-color: var(--pro-input-hover-bg);

    &+.outer_wrap {
        margin-top: 10px;
    }
}