.brand {
    display: flex;
    align-items: center;
    :global(.pro-avatar){
      --wac-avatar-size: 30px;
    }
    img{
      object-fit: contain;
    } 
  }
  