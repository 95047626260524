.dashboard_wrap {
  padding: 0 28px;
}
.tbl_col_title{
  table{
    tbody{
      tr{
        td{
          &:first-child{
            font-weight: 600;
            background-color: var(--pro-secondary);
            position: sticky;
            top: 0;
            z-index: 1;
          }
        }
      }
    }
    thead,tbody{
      th{
        &:first-child{
          &::before{
            top: 0;
            bottom: 0;
          }
        }
      }
      td{
        &:first-child{
          &::before{
            top: 0;
            bottom: 0;
          }
        }
      }
    }
  }
  
}