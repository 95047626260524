.aling_btn {
  transform: rotate(90deg);
}
// .container_root {
//   width: 100%;
//   display: flex;
//   position: relative;
//   border: 1px solid var(--pro-outline-border);
//   background-color: var(--pro-light);
//   border-radius: 5px;
//   align-items: center;
//   button {
//     white-space: nowrap;
//   }
// }

.color_dropdown {
  width: 100%;
  color: #ffffff;
  --bg-color: #0186c0;
  &::before {
    position: absolute;
    content: "";
    background-color: var(--bg-color);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  &.completed {
    --bg-color: #00804c;
  }
  &.pending {
    --bg-color: #0186c0;
  }
  &.in_progress {
    --bg-color: #cf8900;
  }
}

.table_wrap {
  overflow: initial !important;
}

.report_root {
  max-width: 170px;
  padding-right: 17px;
  margin-right: 17px;
  border-right: 1px solid var(--pro-outline-border);
  &:first-child ~ .item {
    padding-left: 0;
  }
  &:last-child {
    border-right: 0px;
    max-width: 500px;
  }
}

.main_container {
  &_inner {
    width: 100%;
  }
  :global {
    .nav {
      // padding-inline: 1.25rem; //padding chnages to margin
      padding-top: 20px;
      margin-bottom: 10px;
      flex-wrap: nowrap;
      background-color: var(--pro-light);
      z-index: 9;
      // border-bottom: 0; //removed the border of nav
      .nav-link {
        white-space: pre;
        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;
          &:not(.active) {
            border: 1px solid transparent;
          }
          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            &::after {
              background-color: var(--pro-primary);
            }
          }
          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}

.interests {
  gap: 3px;
  margin: 5px 0;
}

.offcanvas {
  --pro-offcanvas-width: 1000px !important;
}
