.root {
  width: 100%;
  position: relative;
  .map_wrap {
    width: 100%;
    height: 100%;
  }
  .input_wrap {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    z-index: 2;
    .input {
      // border-bottom-right-radius: 0px;
      // border-bottom-left-radius: 0px;
      padding: 0.8rem 1rem;
      &:active,
      &:focus-visible {
        box-shadow: none;
        border-color: var(--pro-outline-border);
      }
    }
  }
}
.popup {
  z-index: 9999;
  border: 1px solid var(--pro-outline-border);
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--pro-light);
  .option{
    width: 100%;
    font-size: 14px;
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
    &:hover{
        background-color: var(--pro-nav-btn-link-hover);
    }
    .place{
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 1rem;
    }
    [data-suggested-value]{
        font-weight: 400;
    }
    [data-user-value]{
        font-weight: 700;
    }
}
}
