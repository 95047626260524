.container {
  background-color: var(--pro-secondary);
  border: 1px solid var(--pro-outline-border);
  border-radius: 4px;
  padding: 1rem 0.75rem;
  position: relative;
  & + .container {
    margin-top: 14px;
  }
}

.input_wrap {
  :global(.input-wrap) {
    margin-bottom: 0 !important;
  }
}

.delete_btn {
  cursor: pointer;
  width: 30px;
  height: 40px;
  transition: color 0.25s;
  &:hover{
    color: var(--pro-primary);
  }
}
