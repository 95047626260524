// .root {
//   max-width: 660px;
//   margin: auto;
//   .list_wrap {
//     background-color: var(--pro-light);
//     border: 1px solid var(--pro-outline-border);
//     border-radius: 6px;
//   }
//   .notification_item {
//     padding-top: 0.9rem;
//     padding-bottom: 0.9rem;
//     padding-inline: 3px;
//     cursor: pointer;
//     &.un_read {
//       background-color: var(--pro-secondary);
//     }
//     .img_wrap {
//       max-width: 3.3125rem;
//       border-radius: 4px;
//       overflow: hidden;
//       flex: 1;
//     }
//     > p {
//       max-width: 23.125rem;
//     }
//     .date {
//       opacity: 0.7;
//     }
//     & + .notification_item {
//       border-top: 1px solid var(--pro-border-color);
//     }
//   }
// }
// .wrapper {
//   min-height: calc(100vh - 76px);
// }


// .no_item_msg{
//   justify-content: center;
//   min-height: 500px;
// }
.search_order{
    :global{
        .col-auto{
            &:nth-child(1){
                order: 1;
            }
            &:nth-child(2){
                order: 3;
            }
            &:nth-child(3){
                order: 4;
            }
            &:nth-child(4){
                order: 2;
            }
            &:nth-child(5){
                order:5;
            }
            &:nth-child(6){
                order:6;
            }
        }
    }
}