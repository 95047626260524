.platform_root{
    border-bottom: 1px dashed var(--pro-outline-border);
    &:last-child{
        border: none;
    }
}
.social_dtls{
    background-color: var(--pro-secondary-light);
    border-radius: 10px;
    .dtl_col{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.col_label{
            flex: 0 0 14%;
            .submission_date{
                font-size: 14px;
            }
        }
        &.col_info{
            flex: 1;
        }
        &.col_action{
            flex: 0 0 16%;
            text-align: right;
        }
    }
}