// Light theme color variables
$pro-primary-light: #e52f5e;
$pro-primary-text-light: #ffffff;
$pro-primary-hover-light: #d91e4e;
$pro-primary-active-light: #e52f5e;
$pro-bg-primary-light: #e52f5e;
$pro-secondary-light: #f4f5f7;
$pro-secondary-hover-light: #f0f1f3;
$pro-secondary-active-light: #ebecf0;
$pro-success-light: #087300;
$pro-danger-light: #b32318;
$pro-light-light: #fff;
$pro-dark-light: #000;
$pro-outline-light: #fff;
$pro-outline-hover-light: #f8f9fb;
$pro-outline-active-light: #f4f5f7;
$pro-outline-border-light: #dfe3eb;
$pro-disable-text-light: #a5adba;
$pro-disable-bg-light: #f5f6f7;
$pro-input-text-light: #4c4c4c;
$pro-input-hover-bg-light: #fafbfc;
$pro-input-focus-shadow-light: #db5476;
$pro-input-active-light: #7a869a;
$pro-avatar-remaining-bg-light: #dfe1e6;
$pro-nav-btn-link-hover-light: #dc3a6011;
$pro-check-icon-color-light: #db5476;
$pro-range-bg-light: #dbdee3;
$pro-image-gray-overlay-light: #ececec;
$pro-invoice-label-light: #676767;
$pro-input-option-hover-bg-light: #ffecf5;
$pro-input-option-active-bg-light: #ffeced;
$pro-staff-card-bg-light: #fcfcfc;
$pro-green-light: #00b232;
$pro-toggle-bg-light: #d2d1d4;
$pro-pagination-shadow-light: rgba(0, 0, 0, 0.1);
$pro-primary-darken-light: #ae143c;
$pro-table-link-light: #e52f5e;
$pro-dropdown-hover-bg-light: #f5f5f5;
$pro-instagram-light: #e1e9ff;
$pro-youtube-light: #ffe9ea;
$pro-facebook-light: #cfe8ff;
$pro-google-light: #c6d8c8;
$pro-tiktok-light: #e3e3e3;
$pro-task-light: #e52f5e45;

//shimmers
$pro-shimmer-bg-light: #f8f6f6;
$pro-shimmer-gradient-color1-light: #f6f7f8;
$pro-shimmer-gradient-color2-light: #edeef1;
$pro-shimmer-gradient-color3-light: #f6f7f8;
$pro-shimmer-gradient-color4-light: #f6f7f8;

// Dark theme color variables
$pro-primary-dark: #e52f5e;
$pro-primary-text-dark: #ffffff;
$pro-primary-hover-dark: #d91e4e;
$pro-primary-active-dark: #610044;
$pro-bg-primary-dark: #e52f5e;
$pro-secondary-dark: #4e4e4e;
$pro-secondary-hover-dark: #727272;
$pro-secondary-active-dark: #393939;
$pro-success-dark: #00ce06;
$pro-danger-dark: #ff3628;
$pro-light-dark: #1c1c1c;
$pro-dark-dark: #fff;
$pro-outline-dark: #000;
$pro-outline-hover-dark: #727272;
$pro-outline-active-dark: #393939;
$pro-outline-border-dark: #404040;
$pro-disable-text-dark: #a5adba;
$pro-disable-bg-dark: #f5f6f7;
$pro-input-text-dark: #ebeaea;
$pro-input-hover-bg-dark: #2a2a2a;
$pro-input-focus-shadow-dark: #660047a6;
$pro-input-active-dark: #7a869a;
$pro-avatar-remaining-bg-dark: #dfe1e6;
$pro-nav-btn-link-hover-dark: #323232;
$pro-check-icon-color-dark: #a11d2b;
$pro-range-bg-dark: #dbdee3;
$pro-image-gray-overlay-dark: #ececec;
$pro-invoice-label-dark: #676767;
$pro-input-option-hover-bg-dark: #660047a6;
$pro-input-option-active-bg-dark: #660047a6;
$pro-staff-card-bg-dark: #fcfcfc;
$pro-green-dark: #00b232;
$pro-toggle-bg-dark: #d2d1d4;
$pro-pagination-shadow-dark: rgba(255, 255, 255, 0.1);
$pro-primary-darken-dark: #ae143c;
$pro-table-link-dark: #ffffff;
$pro-dropdown-hover-bg-dark: #2e2e2e;
$pro-instagram-dark: #9aa4c1;
$pro-task-dark: #e52f5e45;
$pro-youtube-dark: #ffe9ea;
$pro-facebook-dark: #cfe8ff;
$pro-google-dark: #c6d8c8;
$pro-tiktok-dark: #868686;

//shimmers
$pro-shimmer-bg-dark: #5d5d5d;
$pro-shimmer-gradient-color1-dark: #575757;
$pro-shimmer-gradient-color2-dark: #7c7c7c;
$pro-shimmer-gradient-color3-dark: #414141;
$pro-shimmer-gradient-color4-dark: #5d5d5d;
$pro-nav-link-active-color-dark: #ffffff;

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 16px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}
