.offcanvasLink {
  --pro-offcanvas-width: 680px !important;
}

.offcanvasCreate {
  --pro-offcanvas-width: 480px !important;
}
.icon_msg{
  :global{
    .image-root{
      flex: 0 0 56px;
    }
  }
  figure{
    width: 56px;
    height: 56px;
    border-radius: 12px;
    border: 1px dashed #E52F5E;
    padding-bottom: 0 !important;
    margin-right: 10px;
    img{
      width: 28px;
      height: 28px;
      object-fit: contain;
      left: 0;
      bottom: 0;
      margin: auto;
    }
  }
  span{
    font-size: 16px;
    font-weight: 500;
  }
}
.btn_connect{
  max-width: 113px;
  width: 100%;
  height: 40px;
  line-height: 1.5;
}
.social_wrap >div{
  min-height: 131px;
  display: flex;
  justify-content: center;
}