.image{
    position: relative;
    width: 24px;
    margin-right: 0;
}
.search_order{
    :global{
        .col-auto{
            &:nth-child(1){
                order: 1;
            }
            &:nth-child(2){
                order: 3;
            }
            &:nth-child(3){
                order: 2;
            }
            &:nth-child(4){
                order: 4;
            }
            &:nth-child(5){
                order:5;
            }
        }
    }
}