
.pro_editor_wrapper {
    width: 100%;
    :global{
        .rdw-editor-toolbar{
            box-shadow: none !important;
            border: 1px solid var(--pro-outline-border) !important;
            border-bottom: 0 !important;
            padding: 7px 5px !important; 
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
            
        }
        .rdw-editor-main{
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
    .pro_editor_main {
      padding: 10px;
      border: 1px solid var(--pro-outline-border);
      border-radius: 4px;
      width: 100%;
      &.chats_arb{
        direction: rtl;
        text-align: right;
        p{
          text-align: right;
        }
        :global{
          .public-DraftEditor-content{
            text-align: right !important;
            direction: rtl !important;
            span{
              display: block;
              text-align: right !important;
            }
          }
        }
      }
      :global {
        .public-DraftStyleDefault-block {
          margin: 0;
        }
        .DraftEditor-root {
          min-height: 160px;
          max-height: 200px;
          word-break: break-word;
          overflow-y: auto;
          .public-DraftStyleDefault-ul,
          .public-DraftStyleDefault-ol {
            margin: 0;
            & + * {
              margin-top: 12px;
            }
          }
        }
      }
    }
    .pro_editor_toolbar {
      border: 0;
      border-radius: 0;
      margin-bottom: 0;
      padding: 16px 5px;
      background-color: var(--pro-editor-toolbar-bg);
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
      display: flex;
      row-gap: 10px;
      :global {
        .rdw-inline-wrapper,
        .rdw-list-wrapper,
        .rdw-link-wrapper {
          margin-bottom: 0;
          display: flex;
        }
        .rdw-block-wrapper {
          margin: 0 5px;
          
        }
       
        .rdw-option-wrapper {
          border: 0;
          background-color: transparent;
          transition: background-color;
          min-width: 20px;
          width: 20px;
          position: relative;
          box-shadow: none !important;
          border-radius: 50%;
          margin: 0 8px;
          --scale: 0.7;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          &::before {
            position: absolute;
            border-radius: 0;
            background-color: var(--pro-secondary-active);
            border-radius: 50%;
            content: "";
            height: 25px;
            width: 25px;
            left: 50%;
            top: 50%;
            transition: transform 0.2s ease-in, opacity 0.2s ease-in;
            transform: scale(var(--scale)) translate(-50%, -50%);
            opacity: 0;
            will-change: opacity, transform;
            z-index: -1;
          }
          &:hover::before,
          &.rdw-option-active::before {
            opacity: 1;
            --scale: 1;
          }
          // &:hover, &.rdw-option-active{
          //   background-color: var(--pro-secondary-active);
          // }
        }
        
      }
      .dropdown_wrapper {
        position: relative;
        background-color: var(--pro-light);
        min-width: 100px;
        &:hover {
          background-color: var(--pro-light);
          box-shadow: none;
        }
        > a {
          color: var(--pro-dark);
          font-weight: 500;
          width: 100%;
          :global(.rdw-dropdown-carettoopen) {
            border-top-color: var(--pro-dark);
          }
          :global(.rdw-dropdown-carettoclose) {
            border-bottom-color: var(--pro-dark);
          }
        }
        .dropdown_menu {
          position: absolute;
          top: 100%;
          width: 100%;
          max-height: 180px;
          background-color: var(--pro-light);
          border-color: var(--pro-outline-border);
          overflow-y: auto;
          z-index: 2;
          margin: 10px 0;
          &:hover {
            box-shadow: none;
          }
          li {
            min-height: auto;
            padding: 5px;
            font-size: 14px;
            cursor: pointer;
          }
          :global {
            .rdw-dropdownoption-active {
              background-color: var(--pro-primary);
              color: #ffffff; //no variables used bcause same color
            }
            .rdw-dropdownoption-highlighted:not(.rdw-dropdownoption-active) {
              background-color: var(--pro-input-option-hover-bg);
            }
          }
        }
      }
    }
  }
  [data-theme*="dark"] {
    .pro_editor_toolbar {
      :global(.rdw-option-wrapper img) {
        filter: invert(1);
      }
    }
  }
  .editor_container {
    display: flex;
    flex-direction: column;
    .error_text {
      color: var(--pro-danger);
      font-size: 12px;
      line-height: 1;
      margin-top: 10px;
    }  
  }

  