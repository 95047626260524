.root {
  display: flex;
  justify-content: center;
  padding: 6.125rem 0;
  .image_inner {
    max-width: 6.25rem;
    margin: auto;
    margin-bottom: 0.85rem;
  }
  .title {
    opacity: 0.8;
  }
}
