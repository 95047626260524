.main_wrapper {
  width: 100%;
  position: relative;
  padding-right: calc(var(--pro-gutter-x) * 0.5);
  padding-left: calc(var(--pro-gutter-x) * 0.5);
}

.avatar_container {
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}

.left_sidebar {
  width: 100%;
  max-width: 384px;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;
  overflow: hidden;
  border-right: 1px solid var(--pro-outline-border);
  &_sticky {
    position: relative;
    // &_top {
    //   // position: sticky;
    //   // top: 0;
    //   // background-color: var(--pro-light);
    //   // z-index: 99;
    //   // padding-top: 24px;
    //   // @media(max-height : 799.98px){
    //   //   position: relative;
    //   // }
    // }
    &_scrollWrap {
      padding-bottom: 20px;
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid var(--pro-outline-border);
    }
  }
  .profile_layout_action {
    ul {
      padding: 0;
      li {
        display: flex;
        justify-content: center !important;
      }
    }
  }
  .profile_name_wrap :global(p) {
    margin-top: 5px;
  }
}
:global {
  .pt-custom {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.main_container {
  width: 100%;
  max-width: calc(100% - 385px);
  transition: 0.3s max-width ease-in-out;
  flex: 1;
  &_inner {
    width: 100%;
  }
  :global {
    .nav {
      padding-inline: 1.25rem; //padding chnages to margin
      padding-top: 0.573rem;
      overflow: auto;
      flex-wrap: nowrap;
      overflow-y: hidden;
      position: sticky;
      top: 57px;
      background-color: var(--pro-light);
      z-index: 9;
      // border-bottom: 0; //removed the border of nav
      .nav-link {
        white-space: pre;
        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;
          // &:not(.active){
          //   // border-bottom: 2px solid var(--pro-outline-border);
          // }
          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 7px;
            border: none;
            box-shadow: 0 0 0 1px var(--pro-outline-border);
            &::after {
              background-color: var(--pro-primary);
            }
          }
          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}
.right_sidebar {
  width: 100%;
  max-width: 13rem;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;
  z-index: 9;
  border-left: 1px solid var(--pro-outline-border);
  transition: 0.3s max-width ease-in-out;
}
:global(.close-right-drawer) {
  .right_sidebar {
    max-width: 75px;
  }
  .main_container {
    max-width: calc(100% - 28.7884rem);
  }
}

//fixng profile page pagination

.main_wrapper {
  :global(.pro-pagination-wrapper) {
    z-index: 2;
    right: 0;
    width: calc(100% - 396px);
  }
}

:global(.close-right-drawer) {
  .main_wrapper {
    :global(.pro-pagination-wrapper) {
      right: 76px;
      width: calc(100% - 461px);
    }
  }
}

.hide {
  display: none;
}

.pdf_container {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
}
.assign_wrap {
  border-top: 1px solid var(--pro-outline-border);
  p {
    margin-bottom: 0;
    & ~ p {
      margin-top: 0.6rem;
    }
  }
}
.profile {
  width: 100%;
  > div {
    border-radius: 5px;
    overflow: hidden;
  }
}
.brand_container {
  align-items: center;
  gap: 10px;
  display: flex;
  margin: 10px 0;
  .image_root {
    width: 100%;
    border-radius: 10px;
    position: relative;
    max-width: 34px;
  }
}
