// .container_root {
//   width: 100%;
//   display: flex;
//   position: relative;
//   border: 1px solid var(--pro-outline-border);
//   background-color: var(--pro-light);
//   border-radius: 5px;
//   align-items: center;
//   button {
//     white-space: nowrap;
//   }
// }

.report_root {
  max-width: 170px;
  padding-right: 17px;
  margin-right: 17px;
  border-right: 1px solid var(--pro-outline-border);

  &:first-child~.item {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0px;
    max-width: 500px;
  }
}

.google_card {
  height: 51px;
}
.icon_msg{
  :global{
    .image-root{
      flex: 0 0 56px;
    }
  }
  figure{
    width: 56px;
    height: 56px;
    border-radius: 12px;
    border: 1px dashed #E52F5E;
    padding-bottom: 0 !important;
    margin-right: 10px;
    img{
      width: 28px;
      height: 28px;
      object-fit: contain;
      left: 0;
      bottom: 0;
      margin: auto;
    }
  }
  span{
    font-size: 16px;
    font-weight: 500;
  }
}
.social_wrap >div{
  min-height: 131px;
  display: flex;
  justify-content: center;
  height: 100%;
}
.row > *{
@media (min-width : 1600px) {
  width: 33.333%;
}
}
.cols >div{
  height: 100%;
}